import React from "react"
import styled from "styled-components"
import { COLORS, IMAGES } from "../../constants"
import { P, SectionTitle } from "../../components"
import { MOBBY } from "../../content"
import { Each, LazyImage } from "cn-react-helpers"
import LazyLoad from "react-lazyload"

const Container = styled.header`
  background-color: ${COLORS.WHITE};
  position: relative;
  overflow: hidden;
  padding: 60px 20px 100px 20px;
`

const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  position: relative;
  z-index: 10;
  margin: 0 auto 70px auto;

  @media(max-width: 600px){
    flex-direction: column-reverse;
  }
`

const Mobile = styled.div`
  height: auto;
  width: 100%;
  max-width: 290px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 100%;
    height: auto;
  }

  @media(max-width: 600px){
    margin: 0 auto;
  }
`

const Description = styled.div`
  width: 100%;
  padding-top: 50px;

  @media(max-width: 600px){
    margin-bottom: 30px;
    padding-top: 0px;
  }
`

const Logo = styled.img`
  width: 100%;
  max-width: 270px;
  margin-bottom: 30px;
  display: block;

  @media(max-width: 600px){
    margin:0 auto 30px auto;
  }
`

const Title = styled(SectionTitle)`
  margin-bottom: 30px;
  text-align: left;

  @media(max-width: 600px){
    text-align: center;
  }
`

const Text = styled(P)`
  color: ${COLORS.FONT};

  @media(max-width: 600px){
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
  }
`

const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto 100px auto;

  @media(max-width: 600px){
    grid-template-columns: 1fr;
    gap: 20px;
  }
`

const Feature = styled(P)`
  border: solid ${COLORS.BORDER} 1px;
  border-radius: 10px;
  margin: 0 auto;
  color: ${COLORS.FONT};
  padding: 20px;
`

const Scheme = styled.img`
  width: 100%;
  max-width: 500px;
  display: block;
  margin: 0 auto;
`

function Welcome(){
  return (
    <Container>
      <Content>
        <Mobile>
          <LazyImage
            loader={IMAGES.MOBILE_LOADER}
            source={IMAGES.MOBBY_MOCKUP}
            alt="Mockup"/>
        </Mobile>

        <Description>
          <LazyLoad>
            <Logo src={IMAGES.MOBBY_DARK}/>
          </LazyLoad>

          <Title> Mobby Smart Cities </Title>
          <Text>
            { MOBBY.DESCRIPTION_PAGE }
          </Text>
        </Description>
      </Content>

      <Features>
        <Each items={ MOBBY.FEATURES }>
          {FEATURE => (
            <Feature small>
              { FEATURE }
            </Feature>
          )}
        </Each>
      </Features>

      <LazyLoad height={200}>
        <Scheme src={IMAGES.MOBBY_SCHEME} />
      </LazyLoad>
    </Container>
  )
}

export { Welcome }
