import React from "react"
import styled from "styled-components"
import {
  Section as LayoutSection,
  SectionTitle,
  H
} from "../../components"
import { COLORS } from "../../constants"
import { MOBBY } from "../../content"
import { Each } from "cn-react-helpers"

const Section = styled(LayoutSection)`
  background-color: ${COLORS.PRIMARY}
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  @media(max-width: 1100px){
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 650px){
    grid-template-columns: 1fr;
  }
`

const Feature = styled.article`
  padding: 35px;
  border-radius: 3px;
  min-height: 200px;
  background: ${COLORS.WHITE};
`

const FeatureTitle = styled(H)`
  color: ${COLORS.TITLE};
  margin-bottom: 20px;
`

const FeatureText = styled.p`
  font-size: 1.1em;
  color: ${COLORS.FONT};
`

const FeatureIcon = ({icon}) => {
  const IconWrapper = styled(icon)`
    color: ${COLORS.SECONDARY};
    font-size: 3em;
    margin-bottom: 20px;
  `

  return <IconWrapper/>
}

function Features(){
  return (
    <>
      <SectionTitle> Módulos e funções </SectionTitle>
      <Section>
        <Content>
          <Each items={MOBBY.MODULES.ITEMS}>
            {(MODULE) => (
              <Feature>
                <FeatureIcon icon={MODULE.ICON}/>
                <FeatureTitle> {MODULE.TITLE} </FeatureTitle>
                <FeatureText> {MODULE.CONTENT} </FeatureText>
              </Feature>
            )}
          </Each>
        </Content>
      </Section>
    </>
  )
}

export {
  Features
}
