import React from "react"
import { MainLayout } from "../layouts"
import { SEO, Page, Header, Footer } from "../components"
import { Welcome, Features } from "../partials/mobby/"
import { COLORS } from "../constants"

const IndexPage = () => (
  <MainLayout>
    <SEO themeColor={COLORS.PRIMARY} title="Mobby" />
    <Page>
      <Header/>
      <Welcome/>
      <Features/>
      <Footer/>
    </Page>
  </MainLayout>
)

export default IndexPage
